<script setup>
import { useCommon } from "@/store";

const notifications = computed(() => {
  return useCommon().getNotifications;
});
</script>

<template>
  <div class="auth">
    <notification :items="notifications" />
    <slot />
  </div>
</template>

<style lang="scss">
.auth {
  @apply font-sf-pro;
}
</style>
